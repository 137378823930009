import React from 'react';
import Footer from 'cccisd-footer';
import PropTypes from 'prop-types';
import style from './style.css';

const AppFooter = ({ className }) => {
    return (
        <div className={style.footer}>
            <div className={style.footerLogos}>
                <div className={style.neda} />
                <div className={style.washU} />
                <div className={style.shire} />
                <div className={style.moedc} />
            </div>
            <Footer className={className} />
        </div>
    );
};

AppFooter.defaultProps = {
    className: 'container',
};

AppFooter.propTypes = {
    className: PropTypes.string,
};

export default AppFooter;
