import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const wpUrl = window.cccisd.env.wpUrl;
const Fortress = window.cccisd && window.cccisd.fortress;
const AppHeader = ({ className }) => {
    const manageNav = { url: '/manage', label: 'Manage' };

    let primaryNavLinks = [
        { url: wpUrl + 'aboutUs', label: 'About Us' },
        { url: '/training', label: 'Training' },
        { url: wpUrl + 'contact', label: 'Contact' },
    ];

    Fortress.auth() && primaryNavLinks.push(manageNav);

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logo}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            wrapperClassName={style.wrapper}
        />
    );
};

AppHeader.defaultProps = {
    className: 'container',
};

AppHeader.propTypes = {
    className: PropTypes.string,
};

export default AppHeader;
