import React from 'react';
import AppFooter from '../../components/AppFooter';

const QuestLayout = props => (
    <div>
        <div>{props.children}</div>
        <AppFooter className={props.className} />
    </div>
);
export default QuestLayout;
